<template>
  <div>
    <!-- <TitleSection>
       <a class="float-right" @click.stop="goAnnouncementAll"><small>{{ t('AnnouncementAll') }}</small></a>
    </TitleSection> -->

    <v-row>
      <v-col cols="12" xs="12" lg="12" v-for="(announcement, index) in announcenments" :key="index">
        <AnnouncementItem :announcement="announcement">
          <template #default>
            <ProjectActiveFromAnnouncement :announcenment="announcement" />
          </template>
        </AnnouncementItem>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" lg="12">
       <v-card>
          <v-card-title>
            {{ t('Process complete title') }}
          </v-card-title>
          <v-card-text>
            <div class="announcenment-item-content" v-html= "t('Process complete')" >
              
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleSection from '@/components/general/TitleSection';
import AnnouncementItem from '@/components/announcenments/AnnouncementItem';
import ProjectActiveFromAnnouncement from '@/components/projects/ProjectActiveFromAnnouncement';

import { ref, onMounted } from '@vue/composition-api'
import api from '@/services';
import { useUtils as useUtilsI18n } from '@core/libs/i18n'
import { useRouter } from "@core/utils";

export default {
  components: {
    TitleSection,
    AnnouncementItem,
    ProjectActiveFromAnnouncement
  },
  setup() {
    const { t } = useUtilsI18n();
    const { router } = useRouter();

    const announcenments = ref([]);
    
    const getAnnouncenments = async () => {
      try {
        const { data: response } = await api.getAnnouncenments({
          params: {
            projects: true,
          }
        });
        const { data } = response;
        announcenments.value = [...[data[0]]];
        
      } catch (error) {
      }
    };

    const goAnnouncementAll = () => {
      router.push({ name: "announcements.all" });
    }


    onMounted(() => {
      getAnnouncenments();
    })

    return {
      announcenments,

      t,
      goAnnouncementAll
    }
  }
}
</script>

<style lang="scss" scoped>
  h4 {
    background-color: yellow;
    color: #000;
    width: fit-content;
    padding: 0.5rem;
  }
</style>