<template>
  <div class="">
		<template v-if="projectMeAvalAttach">
			<a class="mx-2" @click.prevent="onSeeAttach">➤ {{ `${ $t("See") } ${ $t("Aval") }` }}</a>
		</template>

		<template v-else>
			<div class="d-flex g10">
				<a class="mx-2" @click.prevent="onSelectFile">➤ {{ `${ $t("Upload") } ${ $t("Aval") }` }}</a>
				<v-chip color="error" class="text-uppercase" small>{{ `${$t('Status')} ${$t('pendingOnly')}` }}</v-chip>
			</div>
			<div v-html="$t('MESSAGE_NOT_UPLOAD_AVAL_PROYECT')" class="mt-2 text-none"></div>
			<v-file-input
				ref="uploader"
				class="d-none"
				accept="application/pdf"
				show-size
				counter
				hide-input
				v-bind="$attrs"
				@change="onInputFile"
			></v-file-input>
		</template>
	</div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed,
  reactive,
} from "@vue/composition-api";
import api from '@/services';

export default {
	props: {
		project: {
			default: null,
		},
	},
	
	setup({ project }, { refs }) {

    const projectId = computed(() => project.id);
    const projectAvals = computed(() => project.avals);
    const projectMeAval = ref(projectAvals.value.find(a => a.is_me_aval) || null);
    const projectMeAvalAttach = computed(() => projectMeAval.value?.attach);
		
		const onSelectFile = () => {
			refs.uploader.$refs.input.click()
		}

		const onSeeAttach = () => {
			const { link } = projectMeAval.value.attach;
			window.open(link, '_blank')
		}

		const onInputFile = async (file) => {
      if(!file) return;
      try {
        const form = new FormData();
        form.append('project_id', projectId.value);
        form.append('file', file);

        const { data } = await api.attachProjectAval(form)
        projectMeAval.value = data.data;
      } catch (error) {
        
      }
    }

		return {
			projectMeAval,
			projectMeAvalAttach,

			onInputFile,
			onSelectFile,
			onSeeAttach
		}
	}
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
</style>