<template>
  <div class="wrapper__project-add-link w-100" v-if="getAnnouncenment">

    <template v-if="hasProjects">

      <h4>{{ $t('Information project') }}</h4>
      <v-divider class="mb-4" />

      <p>
        <strong>{{ t("Status") }}:</strong>
        <project-status :status="findProject.status" class="pl-2" />
      </p>

      <div class="">
        <div class="">
          <a href="" class="mx-2" @click="onProjectView">➤ {{
            t("See Project")
          }}</a>
        </div>
        <div class="">
          <project-aval :project="findProject" />
        </div>
      </div>
    </template>

    <!-- Disable register project -->
    <!-- <template v-if="getAnnouncenment.status && !hasProjects">
       <a href="" class="mx-2" @click="onProjectAdd">{{ t('Start Register') }}</a>

        <ProjectAdd
          v-model="openProjectAdd"
          :announcenment="getAnnouncenment"
          @onDrawerToogle="onDrawerToogle"
          @onProjectAdded="onProjectAdded"
        />
    </template> -->
      
  </div>
</template>

<script>
import ProjectAdd from "@/components/projects/ProjectAdd";
import ProjectAval from '@/components/projects/ProjectAval';
import ProjectStatus from "@/components/projects/Status";

import { ref, computed } from "@vue/composition-api";
import { useRouter } from "@core/utils";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

export default {
  props: {
    announcenment: {
      type: Object,
      default: () => null,
    },
  },

  components: {
    ProjectAdd,
    ProjectAval,
    ProjectStatus
  },
  setup(props) {
    const { t } = useUtilsI18n();
    const { announcenment } = props;
    const { router } = useRouter();
    const getAnnouncenment = ref(announcenment);
    const openProjectAdd = ref(false);

    const hasProjects = computed(() => getAnnouncenment.value.projects.length);
    const findProject = computed(() =>
      getAnnouncenment.value.projects.find((p) => p)
    );

    const onProjectView = ($e) => {
      $e.preventDefault();

      const { id } = findProject.value;

      id && router.push({ name: "projects.show", params: { id } });
    };

    const onProjectAdd = ($e) => {
      $e.preventDefault();

      openProjectAdd.value = true;
    };

    const onDrawerToogle = (value) => {
      openProjectAdd.value = value;
    };

    const onProjectAdded = ({ data: project }) => {
      const announcenment = {
        ...getAnnouncenment.value,
        ...{ projects: [project] },
      };

      getAnnouncenment.value = announcenment;
      openProjectAdd.value = false;
    };

    return {
      getAnnouncenment,
      openProjectAdd,
      hasProjects,
      findProject,

      onProjectView,
      onProjectAdd,
      onDrawerToogle,
      onProjectAdded,

      t,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
</style>
