<template>
  <v-card class="wrapper__announcenment-item" v-if="getAnnouncenment">
    <v-card-title>
      {{ announcementLang.title }}
    </v-card-title>
    <v-card-text v-if="!onlyTile">
      <div class="announcenment-item-content" style="white-space:break-spaces;">{{ announcementLang.description }}</div>

      <div class="announcenment-item-content-footer pt-6">
        <!-- <p><strong>{{ t('Registration Closing') }} {{ getAnnouncenment.initial_date }}</strong></p> -->
         <!-- <p><strong>{{ t('Registration Closing') }} 22-05-2022</strong></p>-->
        <div class="actions">
          <div v-if="isActive">
            <slot name="pm-active-actions"></slot>
          </div>
          <p class="text-uppercase" v-else>{{ statusRead }}</p>
        </div>
        <slot name="admin-actions"></slot>
      </div>
    </v-card-text>
    <v-card-actions>
      <slot></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { useAnnouncement } from '@/composables/announcement/useAnnouncement.js';

export default {
  props: {
    announcement: {
      type: Object,
      default: () => null
    },
    onlyTile: {
      default: false,
      type: Boolean
    }
  },
  setup(props, context) {
    const { t } = useUtilsI18n();
    const { announcement: getAnnouncenment, announcementLang, isActive, statusRead } = useAnnouncement(props, context);

    return {
      getAnnouncenment,
      isActive,
      statusRead,
      announcementLang,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper__announcenment-item {
  .v-card__title {
    font-size: 20px !important;
  }

  .announcenment-item-content-footer {}
}
</style>