<template>
  <WrapperDrawer
    :title="t('Start Register')"
    v-bind="$attrs"
    @input="onInputDrawer"
  >
    <div class="form-steps">
      <v-stepper v-model="activeStep" vertical>
        <!-- Step 1 -->
        <v-stepper-step :complete="activeStep > 1" step="1" :rules="[]">
          <div class="d-flex align-center title-step">
            <div class="d-flex w-100 my-4">
              <div class="d-flex justify-space-between">
                <span class="text--primary text-md font-weight-semibold d-block"
                  >{{ t('Info') }} {{ t('Spanish') }}
                </span>
              </div>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="1" step="1">
          <v-form ref="addAnnouncenmentFormEs" class="mt-2">
            <FormTextArea
              :label="t('Title Project')"
              v-model="addAnnouncenmentFormData.name_es"
              :rules="[validators.required]"
              :rows="5"
            />

            <v-row>
              <v-col>
                <v-btn color="primary" type="button" class="float-right mt-10" @click="onNextStep">
                  {{ t("Next") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>

        <!-- Step 2 -->
        <v-stepper-step :complete="activeStep > 2" step="2" :rules="[]">
          <div class="d-flex align-center title-step">
            <div class="d-flex w-100 my-4">
              <div class="d-flex justify-space-between">
                <span class="text--primary text-md font-weight-semibold d-block"
                  >{{ t('Info') }} {{ t('French') }}
                </span>
              </div>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="2" step="2">
          <v-form ref="addAnnouncenmentFormFr" class="mt-2">
            <FormTextArea
              :label="t('Title Project')"
              v-model="addAnnouncenmentFormData.name_fr"
              :rules="[validators.required]"
              :rows="5"
            />

            <v-row>
              <v-col>
                <v-btn color="primary" type="button" class="float-right mt-10" @click="onNextStep">
                  {{ t("Next") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
      </v-stepper>
    </div>
  </WrapperDrawer>
</template>

<script>
import WrapperDrawer from "@/components/general/WrapperDrawer";
import FormTextArea from "@/components/forms/TextArea";
import FlagLocale from "@/components/general/FlagLocale";

import { ref, reactive, watch, computed } from "@vue/composition-api";
import { required } from "@core/utils/validation";
import api from "@/services";
import { useRouter } from "@core/utils";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

export default {
  components: {
    WrapperDrawer,
    FormTextArea,
    FlagLocale,
  },
  props: {
    announcenment: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtilsI18n();
    const {announcenment } = props;
    const { router } = useRouter();
    const activeStep = ref(1);
    
    const addAnnouncenmentFormEs = ref(null);
    const addAnnouncenmentFormFr = ref(null);
    
    const addAnnouncenmentFormData = reactive({
      name_es: null,
      name_fr: null,
      announcenment_id: announcenment?.id,
    });

    const onChangeFormInputs = (value, property) => {
      addAnnouncenmentFormData[property] = value;
    };

    const onNextStep = () => {
      const { value: step } = activeStep;      
      if(step === 1 && addAnnouncenmentFormEs.value.validate()) activeStep.value += 1; 
      if(step === 2 && addAnnouncenmentFormFr.value.validate()) handleFormSubmit(); 
    }

    const handleFormSubmit = async () => {
      try {
        const { data: response } = await api.addAnnouncenmentProjects(
          addAnnouncenmentFormData
        );
        const { data } = response;

        if (data) {
          emit("onProjectAdded", { data });
          router.push({ name: "projects.show", params: { id: data.id } });
        }
      } catch (error) {}
    };

    const onInputDrawer = (value) => {
      emit("input", value)
    }

    return {
      activeStep,
      addAnnouncenmentFormEs,
      addAnnouncenmentFormFr,
      addAnnouncenmentFormData,

      validators: {
        required,
      },

      onChangeFormInputs,
      onNextStep,
      onInputDrawer,

      t,
    };
  },
};
</script>

<style></style>
